/* eslint-disable no-console */
var first = true;

function getDuration() {
  var start = window.start || new Date();
  var now = new Date();
  var difference = now.getTime() - start.getTime();

  if (difference === 0) {
    return null;
  }

  return difference;
}

exports.onRouteUpdate = function (_ref) {
  var location = _ref.location,
      prevLocation = _ref.prevLocation;

  if ((process.env.NODE_ENV === 'production' && typeof _paq !== 'undefined') || window.dev === true) {
    var _paq2 = window._paq || [];

    var dev = window.dev || null;
    var url = location.pathname + location.search + location.hash;
    var prevUrl = prevLocation && prevLocation.pathname + prevLocation.search + prevLocation.hash; // document.title workaround stolen from:
    // https://github.com/gatsbyjs/gatsby/blob/master/packages/gatsby-plugin-google-analytics/src/gatsby-browser.js

    var sendPageView = function sendPageView() {
      var _document = document,
          title = _document.title;
      prevUrl && _paq2.push(['setReferrerUrl', prevUrl]);

      _paq2.push(['setCustomUrl', url]);

      _paq2.push(['setDocumentTitle', title]);

      _paq2.push(['trackPageView']);

      _paq2.push(['enableLinkTracking']);

      if (dev) {
        console.log("[Matomo] Page view for: ".concat(url, " - ").concat(title));
      }
    };

    if ('requestAnimationFrame' in window) {
      requestAnimationFrame(function () {
        requestAnimationFrame(sendPageView);
      });
    } else {
      // simulate 2 rAF calls
      setTimeout(sendPageView, 32);
    }

    if (first) {
      first = false;

      _paq2.push(['trackEvent', 'javascript', 'load', 'duration', getDuration()]);

      if (dev) {
        console.log("[Matomo] Tracking duration for: ".concat(url));
      }
    }
  }

  return null;
};